import checklistData from "./checklistData";
import { useState, useEffect } from "react";

import html2pdf from 'html2pdf.js';
import moment from 'moment'


function App() {

  const [data, setdata] = useState(checklistData)
  const [checkedQuesitons, setCheckedQuestions] = useState([])

  const [priceIndication, setPriceIndication] = useState(0)

  //default form data
  const [CompanyName, setCompanyName] = useState()
  const [CompanyWebsiteURL, setCompanyWebsiteURL] = useState()
  const [ApplicantName, setApplicantName] = useState()
  const [date, setdate] = useState()
  const [note, setNote] = useState()

  const [startExport, setStartExport] = useState(false)


  useEffect(() => {
    let priceIndicationList = data.PriceIndication;
    let priceIndiction = "";
    let CheckedCount = 0;

    checkedQuesitons.map(q => CheckedCount += q.Points)
    priceIndicationList.map((p, i) => {
      let key = Object.keys(p)
      if (CheckedCount >= key[0]) {
        priceIndiction = priceIndicationList[i][key]
      }
    })

    setPriceIndication(priceIndiction)
  }, [data, checkedQuesitons])

  function toggleData(index, value) {
    let tempObj = data.Questions[index]

    //add
    if (value) {
      setCheckedQuestions(oldArray => [...oldArray, tempObj])
    } else {
      //remove
      let tempArray = checkedQuesitons

      tempArray = tempArray.filter(q => q !== tempObj);
      console.log(tempArray);

      setCheckedQuestions(oldArr => [...tempArray])
    }
  }

  function exportAsPdf() {
    setStartExport(true)

    setTimeout(() => {
      // window.print();
      if (typeof window !== undefined) {
        console.log(CompanyName);
        window.innerHeight = 1080
        window.innerWidth = 1920
        let fileName = CompanyName.split(" ").join("_")
        var element = document.getElementById('element-to-print');

        const options = {
          viewportSize: {
            width: 375,
            height: 812
          }
        };
        html2pdf().from(element).save(fileName);
      }

      setStartExport(false)
    }, 1000);
  }

  return (
    <>
      <div className="container" id="element-to-print">
        <div className="titleContainer">
          <h1>WebSloth Agency Leads Checklist</h1>
        </div>

        <div className="defaultFormData">
          <div className="defaultCheckListDataTitle">
            <h2>Checklist Data</h2>
          </div>
          <div className="formDataInputfields">
            <div className="formDataInput">
              <p>Applicant's Name:</p>
              {!startExport ? <input onChange={(e) => { setApplicantName(e.target.value) }} type={"text"} /> : <p>{ApplicantName}</p>}
            </div>
            <div className="formDataInput">
              <p>Current Date:</p>
              {!startExport ? <input onChange={(e) => { setdate(e.target.value) }} type={"datetime-local"} /> : <p className="expand">{moment(new Date(date).getTime()).format('MMMM Do YYYY - HH:mm')}</p>}
            </div>
            <div className="formDataInput companyInputs">
              <p>Company Name:</p>
              {!startExport ? <input onChange={(e) => { setCompanyName(e.target.value) }} type={"text"} /> : <p>{CompanyName}</p>}
            </div>
            <div className="formDataInput">
              <p>WebSite URL:</p>
              {!startExport ? <input onChange={(e) => { setCompanyWebsiteURL(e.target.value) }} type={"text"} /> : <p>{CompanyWebsiteURL}</p>}
            </div>
            <div className="formDataInput">
              <p>Notes:</p>
              {!startExport ? <textarea onChange={(e) => { setNote(e.target.value) }} type={"date"} /> : <p className="expand">{note}</p>}
            </div>
          </div>
        </div>

        {!startExport && <div>
          <div className="QuestionsTitleContainer">
            <h2>Questions</h2>
          </div>
          <div className="questionContainer">
            {data.Questions.map((q, i) => {
              return <div className="question">
                <div>
                  <p className="questionTitle">{i + 1}. {q.Q}</p>
                </div>
                <div className="inputContainer">
                  <div className="inputDiv">
                    <input onClick={() => toggleData(i, false)} id={`Question${i}Yes`} name={`Question${i}`} type="radio" value="Yes" />
                    <label htmlFor={`Question${i}`}>Yes</label>
                  </div>

                  <div className="inputDiv">
                    <input onClick={() => toggleData(i, true)} id={`Question${i}No`} name={`Question${i}`} type="radio" value="No" />
                    <label htmlFor={`Question${i}`}>No</label>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>}

        {checkedQuesitons.length > 0 && <div className="resultContainer">
          <h2>Results</h2>
          <div className="resultData">
            {checkedQuesitons.map((q, i) => {
              if (q.Checked != false) {
                return <div key={`Question${i}`}>
                  <div>
                    <p className="questionTitle">❌ - {q.Q}</p>
                  </div>
                  <div>
                    <p>{q.A}</p>
                  </div>
                </div>
              }
            })}

          </div>
        </div>}

        {checkedQuesitons.length > 0 && <>
          <div className="priceIndicationContainer">
            <div className="priceIndicationTitleContainer">
              <h2>Price Indication</h2>
            </div>
            <div className="indicationNotice">
              <p>Notice: Please only talk about this when the Lead asks for it. If they don't ask for it it will be
                discussed in the next meeting with them.</p>
            </div>

            <p className="indicationContainer"><span className="indication">Price Indication:</span> {priceIndication}/month</p>
          </div>
          <div>
            {!startExport && <button onClick={() => { exportAsPdf() }} className="exportButton">EXPORT AS PDF</button>}
          </div>
        </>}
      </div>
    </>
  );
}

export default App;
