export default {
    Questions: [
        {
            Q: "Does the website design look modern?",
            A: "We will make sure the new website redesign uses the latest design techniques. And we will make sure the website is in theme with the company.",
            Points: 5
        },
        {
            Q: "Is the website mobile-responsive?",
            A: "In the website redesign we will make sure that every page is responsive so it will look good on all devices.",
            Points: 5
        },
        {
            Q: "Does the website have a clear call-to-action?",
            A: "While redesigning the website we will make sure the main goal of the client will be achieved by integrating a good call-to-action flow.",
            Points: 3,
        },
        {
            Q: "Does the website load quickly?",
            A: "For the new wbesite we will make sure to make is static. This way we will achieve the fastest loading speed for the website.",
            Points: 2
        },
        {
            Q: "Is the website integrated with Google Analytics or any other analytics tool?",
            A: "Google Analytics will be integrated by default in the webiste. This way we and the client will be able to track how many people are on their website, which can be used to convert more sales.",
            Points: 4
        },
        {
            Q: "Is the website regularly updated and maintained?",
            A: "The new website will make use of a CMS(Content Management System). This system can be used in order to create new pages and change already existing content.",
            Points: 10
        },
        {
            Q: "Is the website integrated with social media platforms?",
            A: "We will include all social media links on the new website.",
            Points: 2
        }
    ],
    PriceIndication: [
        { 0: "€50" },
        { 20: "€50-€75" },
        { 30: "€75-€100" },
        { 50: "€100+" }
    ]
}